import {
  Circle,
  type StackProps,
  type TextProps,
  type ThemeName,
  XStack,
} from "tamagui";

import type { IconProps } from "@tamagui/helpers-icon";

import { hairline } from "../utils/hairline";
import { Paragraph2 } from "./Typography";

export type PillProps = {
  text: string;
  notificationDot?: boolean;
  theme?: ThemeName;
  wrapperProps?: StackProps;
  textProps?: TextProps;
  leftIcon?: React.NamedExoticComponent<IconProps>;
  leftIconProps?: IconProps;
  rightIcon?: React.NamedExoticComponent<IconProps>;
  rightIconProps?: IconProps;
};

export const Pill = ({
  text,
  notificationDot,
  theme = "alt1",
  wrapperProps,
  textProps,
  leftIcon: LeftIcon,
  leftIconProps,
  rightIcon: RightIcon,
  rightIconProps,
}: PillProps) => {
  return (
    <XStack
      theme={theme}
      ai="center"
      jc="space-evenly"
      br="$3"
      // No matching tokens
      px={6}
      py={4}
      bg="$color3"
      gap={6}
      borderWidth={hairline}
      borderColor="$color4"
      {...wrapperProps}
    >
      {LeftIcon && <LeftIcon {...leftIconProps} />}
      {notificationDot ? (
        <Circle height={5} width={5} zi={2} backgroundColor="$color13" />
      ) : null}
      <Paragraph2 color="$color" zi={2} {...textProps}>
        {text}
      </Paragraph2>
      {RightIcon && <RightIcon {...rightIconProps} />}
    </XStack>
  );
};
