import { gql } from "@medbillai/graphql-types";

export const getIssuesQuery = gql(/* GraphQL */ `
  query QueryIssues($input: QueryIssuesInput) {
    queryIssues(input: $input) {
      __typename
      id
      title
      openedAt
      closedAt
      lastEvent {
        __typename
        id
        sentAt
        eventType
        participant
        ... on issue__ChatEvent_Type {
          chatBody
        }
        ... on issue__TemplateReleaseRequestedEvent_Type {
          template {
            description
          }
        }
      }
      firstUnseenEvent {
        __typename
        id
        sentAt
        eventType
        participant
        ... on issue__ChatEvent_Type {
          chatBody
        }
        ... on issue__TemplateReleaseRequestedEvent_Type {
          template {
            description
          }
        }
      }
    }
  }
`);

export const getIssuesQueryV2 = gql(/* GraphQL */ `
  query QueryIssuesV2(
    $closed: Boolean
    $unread: Boolean
    $open: Boolean
    $first: Int
    $after: Int
  ) {
    queryIssuesV2(
      closed: $closed
      unread: $unread
      open: $open
      first: $first
      after: $after
    ) {
      __typename
      id
      title
      openedAt
      closedAt
      lastEvent {
        __typename
        id
        sentAt
        eventType
        participant
        ... on issue__ChatEvent_Type {
          chatBody
        }
        ... on issue__TemplateReleaseRequestedEvent_Type {
          template {
            description
          }
        }
      }
      firstUnseenEvent {
        __typename
        id
        sentAt
        eventType
        participant
        ... on issue__ChatEvent_Type {
          chatBody
        }
        ... on issue__TemplateReleaseRequestedEvent_Type {
          template {
            description
          }
        }
      }
    }
  }
`);

export const openIssueMutation = gql(/* GraphQL */ `
  mutation OpenIssue($input: OpenNewIssueInput!) {
    openNewIssue(input: $input) {
      __typename
      id
      title
      openedAt
      closedAt
      # We want the events in descending order because the flashlist for mobile is inverted, this gets reverted for web in processEventsWeb
      userVisibleEvents(order: { sentAt: { dir: DESC } }) {
        # __typename seems to be needed for the polymorphic query here.
        __typename
        id
        eventType
        participant
        sentAt
        sentByProfile {
          name
        }
        ... on issue__DocumentUploadedEvent_Type {
          document {
            id
            name
            dataType
            createdAt
            latestIngestion {
              startedAt
              endedAt
              steps {
                id
                status
              }
              resources(filter: { isLatest: { eq: true } }, first: 1) {
                __typename
                id
                ... on fhirext__InvoiceVersion_Type {
                  title
                }
                ... on fhirext__ExplanationOfBenefitVersion_Type {
                  title
                }
              }
            }
            files {
              id
              sizeBytes
              contentType
            }
          }
        }
        ... on issue__ChatEvent_Type {
          chatBody
        }
        ... on issue__HipaaReleaseRequestedEvent_Type {
          hipaaRelease {
            id
            name
            dateOfBirth
            createdAt
            grantedAt
          }
        }
        ... on issue__BillDisputeEvent_Type {
          eobId
          eob {
            id
            resourceId
            provider {
              display
            }
            facility {
              display
            }
            serviceDate
            decision {
              text
              coding {
                code
              }
            }
          }
        }
        ... on issue__TemplateReleaseRequestedEvent_Type {
          release {
            id
            createdAt
            grantedAt
          }
          template {
            id
            templateType
            displayName
            description
          }
        }
        ... on issue__ClosedEvent_Type {
          feedback {
            id
            rating
          }
        }
      }
      lastEvent {
        __typename
        id
        sentAt
        eventType
        participant
        ... on issue__ChatEvent_Type {
          chatBody
        }
        ... on issue__TemplateReleaseRequestedEvent_Type {
          template {
            description
          }
        }
      }
      firstUnseenEvent {
        __typename
        id
        sentAt
        eventType
        participant
        ... on issue__ChatEvent_Type {
          chatBody
        }
        ... on issue__TemplateReleaseRequestedEvent_Type {
          template {
            description
          }
        }
      }
    }
  }
`);
