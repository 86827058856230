import type { LDContext, LDContextCommon } from "@launchdarkly/node-server-sdk";

// Feature flag keys from LaunchDarkly. Must match configured flags.

export enum Flag {
  CASE_ONBOARDING_AGENT_HUB_PROMPT = "case-onboarding-agent-hub-prompt",
  ENDPOINT_CDP_PROVIDER_OVERRIDE = "endpoint-cdp-provider-override",
  FLEXPA_SIGN_IN_TYPE = "flexpa-sign-in-type",
  GENERATE_REVIEW_ISSUE_TASKS = "generate-review-issue-tasks",
  GENERATE_REVIEW_FINDING_TASKS = "generate-review-finding-tasks",
  PLAYWRIGHT_CAPTURE_HAR_ARTIFACT = "playwright-capture-har-artifact",
  PLAYWRIGHT_FILL_STRATEGY = "playwright-fill-strategy",
  PLAYWRIGHT_MOVE_CURSOR_STRATEGY = "playwright-move-cursor-strategy",
  BROWSERBASE_PROXY_STRATEGY = "browserbase-proxy-strategy",
  BROWSERBASE_STEALTH_STRATEGY = "browserbase-stealth-strategy",
  RUN_AI_CRAWL_ON_SIGN_IN = "run-ai-crawl-on-sign-in",
  RUN_CLASSIC_CRAWL = "run-classic-crawl",
  AI_CRAWL_ENABLE_COVERAGE_AND_MEMBER_DISCOVERY = "ai-crawl-enable-coverage-and-member-discovery",
  AI_CRAWL_ENABLE_CLAIMS_LIST_DISCOVERY = "ai-crawl-enable-claims-list-discovery",
  AI_CRAWL_ENABLE_CLAIM_DETAILS_VISITS = "ai-crawl-enable-claim-details-visits",
  AI_CRAWL_ENABLE_PLAN_DOCUMENTS = "ai-crawl-enable-plan-documents",
  AI_CRAWL_ENABLE_MFA_CONFIGURATION_DISCOVERY = "ai-crawl-enable-mfa-configuration-discovery",
  AI_CRAWL_ENABLE_ACCUMULATORS_DISCOVERY = "ai-crawl-enable-accumulators-discovery",
  RPA_DEVICE = "rpa-device",
  SIGN_IN_ROBOT_AI_MODEL = "sign-in-robot-ai-model",
  SIGN_IN_ROBOT_TYPE = "sign-in-robot-type",
  SIGN_IN_TRACE_FAILED_HEDGES = "sign-in-trace-failed-hedges",
  VELLUM_EOB_EXTRACTION_TAG = "vellum-eob-extraction-tag",
  VELLUM_INVOICE_EXTRACTION_TAG = "vellum-invoice-extraction-tag",
  VELLUM_SBC_EXTRACTION_TAG = "vellum-sbc-extraction-tag",
  VELLUM_MEMBER_CARD_EXTRACTION_TAG = "vellum-member-card-extraction-tag",
  WORKFLOWAI_INVOICE_EXTRACTION_TASK_VERSION = "workflowai-invoice-extraction-task-version",
  AI_INGESTION_PERSIST_TRANSLATED_RESOURCES = "ai-ingestion-persist-translated-resources",
  VELLUM_URL_ANALYSIS_RELEASE_TAG = "vellum-url-analysis-release-tag",
  VELLUM_CLASSIFY_CRAWLER_ARTIFACTS_RELEASE_TAG = "vellum-classify-crawler-artifacts-release-tag",
  VELLUM_CLASSIFY_DOCUMENT_RELEASE_TAG = "vellum-classify-document-release-tag",
  VELLUM_RESOURCE_ANALYZER_RELEASE_TAG = "vellum-resource-analyzer-release-tag",
  VELLUM_RESOURCE_RANKER_RELEASE_TAG = "vellum-resource-ranker-release-tag",
  ONBOARDING_V2_STATIC_FLOW = "onboarding-v2-static-flow",
  ONBOARDING_V2_BASIC_DYNAMIC_FLOW = "onboarding-v2-basic-dynamic-flow",
  ONBOARDING_TIME_LIMIT = "onboarding-time-limit",
  DENTAL_VISION_PHARMACY_SCREEN_PLAN_TYPES = "dental-vision-pharmacy-screen-plan-types",
  POST_CRAWL_DOCUMENT_INDEXING = "document-index-for-rag",
  AI_CRAWL_ENABLE_CLAIM_PDF_VISITS = "ai-crawl-enable-claim-pdf-visits",
  EOB_FINDINGS_V1_ENABLED = "eob-findings-v1",
  AUTO_ANALYZE_SIGN_IN_LOGS = "auto-analyze-sign-in-logs",
  CASE_INTAKE_V1 = "case-intake-v-1",
  CASE_INTAKE_V1_PHASE_2 = "case-intake-v-1-phase-2",
  USER_IP_PROXY_ENABLED = "user-ip-proxy-enabled",
}

export type Contexts = "endpointSlug" | "user";

export type KnownLDContext = { kind: "multi" } & {
  [K in Contexts]?: "multi" | LDContextCommon;
};

({}) as KnownLDContext satisfies LDContext;
